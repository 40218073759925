import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Login from "./Login";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";


const PaginaLogin = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Conectare la platforma'} />
            <Login />
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaLogin;
