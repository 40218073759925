import React, {useEffect, useState} from 'react';
import axiosInstance from "../Helper/AxiosHelper";
import {GiPlainCircle} from "react-icons/gi";
import thumb from '../../assets/images/service-details-thumb.jpg';
import Quill from "../../lib/Quill";
import blogImg1 from "../../assets/images/blog/1.jpg";
import FileDownload from "js-file-download";
import { baseURL } from '../Helper/AxiosHelper'

const Noutate = ({course}) => {
    const [isShown, setIsShown] = useState(false);
    const [title, setTitle] = useState(course.title);
    const [text, setText] = useState(course.text);
    const [isPublic, setIsPublic] = useState(course.isPublic);
    const [newsType, setNewsType] = useState(course.newsType);
    const [format, setFormat] = useState(course.format);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(`${baseURL}/api/cntr/newsimage/${course.id}`);
    const [imageToSend, setImageToSend] = useState();
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isNewsTypeEditable, setIsNewsTypeEditable] = useState(false);
    const [isFormatEditable,setIsFormatEditable] = useState(false);
    const [newsFiles, setNewsFiles] = useState([]);
    const [fileToSend, setFileToSend] = useState(null);

    async function fetchImage() {
        await axiosInstance.get('/api/cntr/newsimage/id', {
            params: {
                id: course.id
            },
        }).then(r => {
            if (!(r.status === 200)) {
                throw new Error(`Error! status: ${r.status}`);
            }
            const image = r.data;
            setImage(`data:${image.type};base64,${image.data}`);
            // setLoading(false);
        }).catch((error) => console.log(error))

    }

    async function fetchData() {
        await axiosInstance.get('/api/cntr/newsfile/id', {
            params: {
                id: course.id
            },
        }).then(r => {
            setNewsFiles(r.data);
        })
    }

    useEffect(() => {
        fetchData()
        setImageUrl(`${baseURL}/api/cntr/newsimage/${course.id}`);
        console.log("intra in effect")
        // fetchImage()
    },[course.id]);



    const showEditor = (e) => {

        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };
    const uploadImage = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageToSend(event.target.files[0]);
    }

    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        setIsFormatEditable(false);
        setIsNewsTypeEditable(false);
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const newsJson = JSON.stringify({
            title : title,
            text : stringHtml,
            isPublic : isPublic,
            format : format,
            newsType : newsType,
        })
        try {
            const response = await axiosInstance.patch('/api/cntr/news/update', newsJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: course.id
                }
            })
            const data1 = await response;

        } catch(error) {
            console.log(error)
        }
    }


    function handleTitleChange(e) {
        setTitle(e.target.value);
    }

    function selectMaterialStatus() {
        setIsPublic(!isPublic);
    }


    function handleMaterialTypeChange(e) {
        setNewsType(e.target.value)
    }

    function handleFormatChange(e) {
        setFormat(e.target.value)
    }

    const handleFileSubmit = async(event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file", fileToSend);
        formData.append("id", course.id);
        try {

            const response = await axiosInstance.patch('/api/cntr/news/add/files', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            })
        } catch(error) {
            console.log(error)
        }
        axiosInstance.get('/api/cntr/newsfile/id', {
            params: {
                id: course.id
            },
        }).then(r => {
            setNewsFiles(r.data);
        })

    }

    function uploadFile(e) {
        setFileToSend(e.target.files[0]);
    }

    const downloadFile = (file) => (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/cntr/newsfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, file.file, file.type)
        })
    }



    const handleSubmit = async(event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("image", imageToSend);
        formData.append("id", course.id);
        try {
            const response = await axiosInstance.patch('/api/cntr/newsimage/update/image', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            })
            const res = await response;

            if (res.status === 200) {
                console.log(res.data)
                window.location.reload()
            }

        } catch(error) {
            console.log(error)
        }
    }

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                {localStorage.getItem('role') === 'admin' && (
                                    <div className="service-category-widget" style={{textAlign: 'center'}}>
                                        <form onSubmit={updateText}>
                                            <label htmlFor="filePicker3" style={{cursor:"pointer"}}>
                                                <div onClick={selectMaterialStatus} style={{textAlign: 'center'}} >
                                                    {isPublic
                                                        ? <h3 style={{ color: 'red'}}><GiPlainCircle /> Ascunde Noutate</h3>
                                                        : <h3 style={{ color: 'green'}}><GiPlainCircle /> Publica Noutate</h3>
                                                    }
                                                </div>
                                                <input id="filePicker3" type="submit" name="file3" style={{display: "none"}}></input>
                                            </label>
                                        </form>
                                    </div>
                                )}
                                <div className="service-category-widget">
                                    {localStorage.getItem('role') === 'admin' && (
                                        <form onSubmit={updateText}>
                                            <ul>
                                                {isNewsTypeEditable && (
                                                    <li>
                                                        <label htmlFor="filePicker1">
                                                            <i className="fal fa-tag"></i>
                                                            <input id="filePicker1" type="text" placeholder={newsType} name="file1" style={{maxWidth: '190px'}} onChange={handleMaterialTypeChange}>
                                                            </input>
                                                            <button type="submit" >
                                                                <i className="fal fa-upload" />
                                                            </button>
                                                        </label>
                                                    </li>
                                                )}
                                                {!isNewsTypeEditable && (
                                                    <li onClick={() => setIsNewsTypeEditable(!isNewsTypeEditable)}>
                                                        <i className="fal fa-tag"></i>{newsType}
                                                    </li>
                                                )}
                                                {isFormatEditable && (
                                                    <li>
                                                        <label htmlFor="filePicker2">
                                                            <i className="fal fa-bookmark"></i>
                                                            <input id="filePicker2" type="text" placeholder={format} name="file2" style={{maxWidth: '190px'}} onChange={handleFormatChange}>
                                                            </input>
                                                            <button type="submit" >
                                                                <i className="fal fa-upload"></i>
                                                            </button>
                                                        </label>
                                                    </li>
                                                )}
                                                {!isFormatEditable && (
                                                    <li onClick={() => setIsFormatEditable(!isFormatEditable)}>
                                                        <i className="fal fa-bookmark"></i>{format}
                                                    </li>
                                                )}

                                            </ul>
                                        </form>
                                    )}
                                    {localStorage.getItem('role') !== 'admin' && (
                                        <ul>
                                            <li>
                                                <i className="fal fa-tag"></i>{course.newsType}
                                            </li>
                                            <li>
                                                <i className="fal fa-bookmark"></i>{course.format}
                                            </li>
                                        </ul>
                                    )}
                                </div>

                                {localStorage.getItem('role') === 'admin' && (
                                    <div className="service-category-widget">
                                        <form onSubmit={handleSubmit}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="imagePicker"  style={{cursor:"pointer"}}>
                                                        <i className="fal fa-upload"></i>Incarca imagine
                                                        <input id="imagePicker" type="file" name="file" onChange={uploadImage} style={{display: "none"}}></input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                        <form onSubmit={handleFileSubmit}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="filePicker"  style={{cursor:"pointer"}}>
                                                        <i className="fal fa-upload"></i>Incarca fisier
                                                        <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                )}
                                <div className="service-category-widget">
                                    <p><b>Documente atasate:</b></p>
                                    {newsFiles.length === 0 && (
                                        <ul>
                                            <li>
                                                <br/>
                                                Nu exista atasamente.
                                            </li>
                                        </ul>
                                    )}
                                    <ul>
                                        {newsFiles.map((file) => {
                                            return(
                                                <li key={file.id} onClick={downloadFile(file)} style={{cursor:"pointer"}}>
                                                    <i className="fal fa-tag"></i>{file.file}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    {course.isImage && (
                                        // <img src={image} alt="" />
                                        <img src={imageUrl} alt="" loading="lazy"/>
                                    )}
                                    {!course.isImage && (
                                        <img src={thumb} alt="" />
                                    )}
                                </div>
                                {isShown && (
                                    <div>
                                        <Quill
                                            actualText={{
                                                container: document.getElementById('tryEdit'),
                                            }}
                                        />
                                        <button type="submit" onClick={showEditor}>
                                            Save
                                        </button>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-section-title text-center" style={{paddingTop: '50px'}}>
                                            {isTitleEditable && (
                                                <form onSubmit={updateText}>
                                                    <label htmlFor="filePicker1">
                                                        <input id="filePicker1" type="text" placeholder={title} name="file1" onChange={handleTitleChange}>
                                                        </input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </form>
                                            )}
                                            {!isTitleEditable && (
                                                <h3 className="appie-title" onClick={() => setIsTitleEditable(!isTitleEditable)}>{title}</h3>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div id="tryEdit" className="content text-justify" onClick={showEditor} dangerouslySetInnerHTML={{__html: text}}>
                                    {/*<p>*/}
                                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Noutate;
