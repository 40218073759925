import React from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import FormularIncarcare from "./FormularIncarcare";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";

const PaginaIncarcaPlanDeAfaceri = () => {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Sectiune incarcare'}/>
            <FormularIncarcare />
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaIncarcaPlanDeAfaceri;
