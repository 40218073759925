import React from 'react';

const GrupTinta = ({ className }) => {
    return (
        <>
            <section className={`appie-services-2-area pt-100 pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Parteneriate</h3>
                                <p style={{fontSize: '20px'}}> <b>In cadrul proiectulului avem parteneriate incheiate cu urmatoarele institutii:</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Brasov</b>
                                </p>
                                <p>
                                    Braşov, str. Lungă nr. 1 A
                                </p>
                                <p>
                                    Nr. telefon: 0268.416.419, 0268.416.879
                                </p>
                                <p>
                                    Nr. fax: 0268.412.552
                                </p>
                                <p>
                                    E-mail: ajofm@bv.anofm.ro
                                </p>
                                <p>
                                    Pagina web: www.ajofm-bv.ro
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Sibiu</b>
                                </p>
                                <p>
                                    Sibiu, str. Morilor nr. 51 A
                                </p>
                                <p>
                                    Nr. telefon: 0269.210.882
                                </p>
                                <p>
                                    Nr. fax: 0269.230.089
                                </p>
                                <p>
                                    E-mail: ajofm@sb.anofm.ro
                                </p>
                                <p>
                                    Pagina web: www.sibiu.anofm.ro
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Harghita</b>
                                </p>
                                <p>
                                    Miercurea Ciuc, bd. Frăţiei nr. 2
                                </p>
                                <p>
                                    Nr. telefon: 0266.313.423
                                </p>
                                <p>
                                    Nr. fax: 0266.312.885
                                </p>
                                <p>
                                    E-mail: ajofm@hr.anofm.ro
                                </p>
                                <p>
                                    Pagina web: www.locuridemuncaharghita.ro
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Covasna</b>
                                </p>
                                <p>
                                    Sfântu Gheorghe, str. General Grigore Bălan nr. 14
                                </p>
                                <p>
                                    Nr. telefon: 0267.312.157, 0267.317.045
                                </p>
                                <p>
                                    Nr. fax: 0267.312.157, 0267.317.045
                                </p>
                                <p>
                                    E-mail: ajofm@cv.anofm.ro
                                </p>
                                <p>
                                    Pagina web: www.covasna.anofm.ro
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Mures</b>
                                </p>
                                <p>
                                    Târgu Mureş, str. Iuliu Maniu nr. 2
                                </p>
                                <p>
                                    Nr. telefon: 0265.269.247
                                </p>
                                <p>
                                    Nr. fax: 0265.268.035
                                </p>
                                <p>
                                    E-mail: ajofm@ms.anofm.ro
                                </p>
                                <p>
                                    Pagina web: www.mures.anofm.ro
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Alba</b>
                                </p>
                                <p>
                                    Alba Iulia, str. Nicolae Titulescu nr. 10 B, bl. Columna
                                </p>
                                <p>
                                    Nr. telefon: 0258.811.470, 0258.831.137
                                </p>
                                <p>
                                    Nr. fax: 0258.811.470
                                </p>
                                <p>
                                    E-mail: ajofm@ab.anofm.ro
                                </p>
                                <p>
                                    Pagina web: www.alba.anofm.ro
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GrupTinta;
