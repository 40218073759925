import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import SectiuniDocumente from "./SectiuniDocumente";
import axiosInstance from "../Helper/AxiosHelper";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";
import BorderSpinner from "../../lib/BorderSpinner";
import {FaExclamationTriangle} from "react-icons/fa";

const PaginaDocumenteAdmin = () => {
    const [isLoading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get('/api/cntr/user/all');
                if (!(response.status === 200)) {
                    setIsError(true);
                    throw new Error(`Error! status: ${response.status}`);
                }
                const res = await response;
                setUsers(res.data);
                setLoading(false);
            } catch (error) {
                setIsError(true);
                console.log(error);
            }

        }
        fetchData();
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={"Documente incarcate"}/>
            {isError && (
                <section className="appie-blog-3-area pt-90 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-sidebar" align="center">
                                    <h3 style={{color: 'red'}}><FaExclamationTriangle /></h3>
                                    <h3>A aparut o eroare! Contacteaza echipa suport!</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {!isError && isLoading && (
                <section className="appie-blog-3-area pt-90 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="appie-section-title text-center">
                                    <h3 className="appie-title">Documentele se incarca:</h3>
                                </div>
                                <div className="appie-section-title text-center">
                                    <BorderSpinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {!isError && !isLoading && (
                <SectiuniDocumente users={users} />
            )}

            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaDocumenteAdmin;
