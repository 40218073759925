import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import axiosInstance from "../Helper/AxiosHelper";
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Noutate from "./Noutate";

const PaginaNoutate = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    const [course, setCourse] = useState();
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    const idParam = location.search.substring(4);

    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get('/api/cntr/news/id', {
                params: {
                    id: idParam
                },
                // responseType:"blob"
            }).then(r => {
                setCourse(r.data);
                setLoading(false);
            })
        }
        fetchData()

    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect />
            <Noutate course={course}/>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaNoutate;
