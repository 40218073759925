import React from 'react';
import PaginaJobForEdit from "./Joburi/PaginaJobForEdit";
import {Navigate} from "react-router-dom";
import PaginaActivitateForEdit from "./ResurseProiect/PaginaActivitateForEdit";

const ProtectedActivitateRouteAdmin = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaActivitateForEdit />
    }
    return <Navigate to="/activitate1" />
};

export default ProtectedActivitateRouteAdmin;
