import React from 'react';
import { Link } from 'react-router-dom';
import heroThumb from '../../assets/images/headpic.png';

const Footer = ({ className }) => {
    return (
        <>
            <section className={`appie-footer-area appie-footer-3-area ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-6">

                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Pentru inscrieri in proiect</h4>
                                <ul>
                                    <li>
                                        <a>
                                            <i className="fal fa-envelope" /> neetnomorecentru@gmail.com
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <i className="fal fa-phone" /> 0722 603 820
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <i className="fal fa-phone" /> 0724 484 965
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-about-widget footer-about-widget-3">
                                <div className="logo">

                                    {/*<a>*/}
                                    {/*    /!*<img src={heroThumb} alt="" />*!/*/}
                                    {/*    <h3>Contact</h3>*/}
                                    {/*</a>*/}
                                </div>
                                {/*<div className="social mt-30">*/}
                                {/*    <ul>*/}
                                {/*        <li>*/}
                                {/*            <a target="_blank">*/}
                                {/*                <i className="fab fa-facebook-f" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Contact</h4>
                                <ul>
                                    <li>
                                        <a>
                                            <i className="fal fa-map-marker-alt" /> Strada Traian 122- 124,
                                            <br/>Bucuresti 030576
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="row" style={{ flexDirection: "row", justifyContent: "center" }}>
                        <div className="footer-copyright d-flex align-items-center">
                            <p>Copyright © 2022. Website detinut de GoodStory. Toate drepturile sunt rezervate.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;
