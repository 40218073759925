import React, {useEffect, useState} from 'react';
import axiosInstance from "../Helper/AxiosHelper";
import {GiPlainCircle} from "react-icons/gi";
import Textarea from "@mui/joy/Textarea";
import FileDownload from "js-file-download";
import Quill from "../../lib/Quill";

const Activitate = ({activity}) => {
    const [isShown, setIsShown] = useState(false);
    const [title, setTitle] = useState(activity.title);
    const [text, setText] = useState(activity.text);
    const [isPublic, setIsPublic] = useState(activity.isPublic);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [newsFiles, setNewsFiles] = useState([]);
    const [fileToSend, setFileToSend] = useState(null);

    async function fetchData() {
        await axiosInstance.get('/api/cntr/activityfile/id', {
            params: {
                id: activity.id
            },
            // responseType:"blob"
        }).then(r => {
            console.log(r.data)
            setNewsFiles(r.data);
            // setLoading(false);
        })
    }

    useEffect(() => {

        fetchData()

    },[]);

    const showEditor = (e) => {

        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };


    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const activityJson = JSON.stringify({
            title : title,
            text : stringHtml,
            isPublic : isPublic,
        })
        try {

            const response = await axiosInstance.patch('/api/cntr/activity/update', activityJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: activity.id
                }
            })
            const data1 = await response;

        } catch(error) {
            console.log(error)
        }
    }


    function handleTitleChange(e) {
        setTitle(e.target.value);
    }

    function selectMaterialStatus() {
        setIsPublic(!isPublic);
    }


    const handleFileSubmit = async(event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file", fileToSend);
        formData.append("id", activity.id);
        try {
            const response = await axiosInstance.patch('/api/cntr/activity/add/files', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            })
        } catch(error) {
            console.log(error)
        }
        axiosInstance.get('/api/cntr/activityfile/id', {
            params: {
                id: activity.id
            },
        }).then(r => {
            setNewsFiles(r.data);
        })

    }

    function uploadFile(e) {
        setFileToSend(e.target.files[0]);
        console.log(e.target.files[0])

    }

    const downloadFile = (file) => (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/cntr/activityfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, file.file, file.type)
        })
    }

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                {localStorage.getItem('role') === 'admin' && (
                                    <div className="service-category-widget" style={{textAlign: 'center'}}>
                                        <form onSubmit={updateText}>
                                            <label htmlFor="filePicker3" style={{cursor:"pointer"}}>
                                                <div onClick={selectMaterialStatus} style={{textAlign: 'center'}} >
                                                    {isPublic
                                                        ? <h3 style={{ color: 'red'}}><GiPlainCircle /> Ascunde Activitate</h3>
                                                        : <h3 style={{ color: 'green'}}><GiPlainCircle /> Publica Activitate</h3>
                                                    }
                                                </div>
                                                <input id="filePicker3" type="submit" name="file3" style={{display: "none"}}></input>
                                            </label>
                                        </form>
                                    </div>
                                )}


                                {localStorage.getItem('role') === 'admin' && (
                                    <div className="service-category-widget">
                                        <form onSubmit={handleFileSubmit}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="filePicker"  style={{cursor:"pointer"}}>
                                                        <i className="fal fa-upload"></i>Incarca fisier
                                                        <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                )}
                                <div className="service-category-widget">
                                    Documente incarcate
                                    <ul>
                                        {newsFiles.map((file) => {
                                            return(
                                                <li key={file.id} onClick={downloadFile(file)} style={{cursor:"pointer"}}>
                                                    <i className="fal fa-tag"></i>{file.file}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                {isShown && (
                                    <div>
                                        <Quill
                                            actualText={{
                                                container: document.getElementById('tryEdit'),
                                            }}
                                        />
                                        <button type="submit" onClick={showEditor}>
                                            Save
                                        </button>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-section-title text-center" style={{paddingTop: '50px'}}>
                                            {isTitleEditable && (
                                                <form onSubmit={updateText}>
                                                    <label htmlFor="filePicker1">
                                                        <input id="filePicker1" type="text" placeholder={title} name="file1" onChange={handleTitleChange}>
                                                        </input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </form>
                                            )}
                                            {!isTitleEditable && (
                                                <h3 className="appie-title" onClick={() => setIsTitleEditable(!isTitleEditable)}>{title}</h3>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div id="tryEdit" className="content text-justify" onClick={showEditor} dangerouslySetInnerHTML={{__html: text}}>
                                    {/*<p>*/}
                                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Activitate;
