import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import heroThumb from '../../assets/images/Banner.jpg';
import {scroller} from "react-scroll";

function Drawer({ drawer, action, lang }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        // e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };

    const navigate = useNavigate();
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 0, spy: true});
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const scrollToHomePage = async (target) => {
        if (window.location.pathname !=='/') {
            await navigate('/');
            await delay(100);
        }
        scrollTarget(target);
    };

    const scrollToPage = async (target) => {
        if (window.location.pathname !=='/concurs-plan-de-afaceri') {
            await navigate('/concurs-plan-de-afaceri');
            await delay(100);
        }
        scrollTarget(target);
    };

    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={`offcanvas_menu_wrapper ${
                                    drawer ? 'active' : ''
                                }`}
                            >
                                <div className="canvas_close">
                                    <a href="src/components/Mobile/Drawer#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={heroThumb} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, 'home')}
                                            id="home"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <Link to="/">
                                                Despre proiect
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height:
                                                        item === 'home' ? itemSize : '0px',
                                                }}
                                            >
                                                <li onClick={() => scrollToHomePage('sumar-proiect')}>
                                                    <Link>Sumar proiect</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('campanie-de-informare')}>
                                                    <Link>Grup tinta</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('selectare-si-inscriere')}>
                                                    <Link>Obiectivele proiectului</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('activitati-principale')}>
                                                    <Link>Activitatile din cadrul proiectului</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('grupul-tinta')}>
                                                    <Link>Parteneriate</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'concurs-plan')}
                                            id="concurs-plan"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <Link>
                                                Resurse proiect
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height:
                                                        item === 'concurs-plan' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link to="/activitate1">Activitate A1</Link>
                                                </li>
                                                <li>
                                                    <Link to="/activitate2">Activitate A2</Link>
                                                </li>
                                                <li>
                                                    <Link to="/activitate3">Activitate A3</Link>
                                                </li>
                                                <li>
                                                    <Link to="/activitate4">Activitate A4</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'firme')}
                                            id="firme"
                                            className="menu-item-has-children active"
                                        >

                                                <Link to="/cursuri">Cursuri</Link>

                                        </li>

                                        <li
                                            onClick={(e) => handler(e, 'joburi')}
                                            id="joburi"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/joburi">Joburi</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'monitorizare-proiect')}
                                            id="monitorizare-proiect"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/Noutati">Noutati</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                        {localStorage.getItem('user') && localStorage.getItem('role') !== 'admin' && (
                                            <li
                                                onClick={(e) => handler(e, 'incarca-plan-de-afaceri')}
                                                id="incarca-plan-de-afaceri"
                                                className="menu-item-has-children active"
                                            >
                                                <Link to="/incarca-plan-de-afaceri">Incarca plan de afaceri</Link>
                                            </li>
                                        )}
                                        {localStorage.getItem('role') === 'admin' && (
                                            <li
                                                onClick={(e) => handler(e, 'documente-admin')}
                                                id="documente-admin"
                                                className="menu-item-has-children active"
                                            >
                                                <Link to="/documente-admin">Documente Admin</Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a target="_blank">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a>
                                                <i className="fal fa-envelope"></i>{' '}
                                                neetnomorecentru@gmail.com
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-phone"></i> 0722 603 820
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-phone"></i> 0724 484 965
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-map-marker-alt"></i>{' '}
                                                Strada Traian 122- 124,
                                                <br/>Bucuresti 030576
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Drawer;
