import React from 'react';
import axiosInstance from "../Helper/AxiosHelper";
import FileDownload from "js-file-download";

const IntroA3 = () => {
    function downloadFile(e) {
        e.preventDefault();
        let fileName = "";
        if (e.target.id === 'doc1') {
            fileName = "Model_CV_ro.doc";
        }
        // else if (e.target.id === 'doc2') {
        //     fileName = "Afis A4_Regiunea Centru_3_mm_bd.pdf";
        // }
        // else if (e.target.id === 'doc3') {
        //     fileName = "Metodologie";
        // }

        axiosInstance.get(`/api/cntr/files/downloads/${fileName}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, `${fileName}`)
        })
    }

    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="col-lg-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-2">
                                        <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                    </div>
                                    <div className="col-lg-8">
                                        <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                            Model CV
                                        </p>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="content" >
                                            <button className="main-btn" id="doc1" onClick={downloadFile}>
                                                <i className="fal fa-download" />Descarca
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="appie-blog-item-3 mt-30">*/}
                        {/*    <div className="col-lg-12">*/}
                        {/*        <div className="row align-items-center">*/}
                        {/*            <div className="col-lg-2">*/}
                        {/*                <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-8">*/}
                        {/*                /!*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*!/*/}
                        {/*                <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>*/}
                        {/*                    Afis Regiunea Centru*/}
                        {/*                </p>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-2">*/}
                        {/*                <div className="content" >*/}
                        {/*                    <button className="main-btn" id="doc2" onClick={downloadFile}>*/}
                        {/*                        <i className="fal fa-download" />Descarca*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntroA3;
