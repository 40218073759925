import React, {useEffect, useState} from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroResurseProiect from "./HeroResurseProiect";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import ListaActivitati from "./ListaActivitati";
import axiosInstance from "../Helper/AxiosHelper";
import IntroA4 from "./IntroA4";
import {useAtom} from "jotai";
import {userAtom} from "../Login/Login";

const PaginaA4 = () => {
    const [drawer, drawerAction] = useToggle(false);
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/cntr/activity/a4/all').then((response) => {
            setActivities(response.data);
        })
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Resurse pentru A.4. Curs de antreprenoriat si acordarea de subventii'} activity={'A4'}/>
            <IntroA4 />
            <ListaActivitati activities={activities} setActivities={setActivities} activityType={'a4'}/>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaA4;
