import React from 'react';
import Drawer from "../Mobile/Drawer";
import Header from "./Header";
import Hero from "./Hero";
import Services from "./Services";
// import Team from "./Team";
// import Testimonial from "./Testimonial";
// import Blog from "./Blog";
// import Testimonial2 from "./Testimonial2";
// import Blog2 from "./Blog2";
import Project from "./Project";
import Footer from "./Footer";
import BackToTop from "../BackToTop";
import useToggle from "../../Hooks/useToggle";
import Details from "./Details";
import CampanieDeInformareSiPromovare from "./CampanieDeInformareSiPromovare";
import SelectareSiInscriereGrupTinta from "./SelectareSiInscriereGrupTinta";
import FormareProfesionala from "./FormareProfesionala";
import MediereaMuncii from "./MediereaMuncii";
import ActivitatiPrincipale from "./ActivitatiPrincipale";
import GrupTinta from "./GrupTinta";
// import {Divider} from "@mui/material";
import { Element } from 'react-scroll';

const Home = () => {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Hero />
            <Element id='sumar-proiect' name='sumar-proiect'>
                <Services className="pt-190" />
            </Element>
            {/*<Divider variant={"middle"}/>*/}

            <Element id='campanie-de-informare' name='campanie-de-informare'>
                <CampanieDeInformareSiPromovare />
            </Element>
            {/*<Divider variant={"middle"}/>*/}
            <Element id='selectare-si-inscriere' name='selectare-si-inscriere'>
                <SelectareSiInscriereGrupTinta />
            </Element>
            {/*<Divider variant={"middle"}/>*/}
            <Element id='activitati-principale' name='activitati-principale'>
                <ActivitatiPrincipale />
            </Element>
            {/*<Divider variant={"middle"}/>*/}
            <Element id='grupul-tinta' name='grupul-tinta'>
                <GrupTinta />
            </Element>

            {/*<Divider variant={"middle"}/>*/}
            {/*<Details />*/}

            {/*<Divider variant={"middle"}/>*/}

            {/*<Element id='formare-profesionala' name='formare-profesionala'>*/}
            {/*    <FormareProfesionala />*/}
            {/*</Element>*/}
            {/*<Divider variant={"middle"}/>*/}
            {/*<Element id='medierea-muncii' name='medierea-muncii'>*/}
            {/*    <MediereaMuncii />*/}
            {/*</Element>*/}
            {/*<Team />*/}
            {/*<Testimonial />*/}
            {/*<Blog />*/}
            {/*<Testimonial2 />*/}
            {/*<Blog2 />*/}
            {/*<Divider variant={"middle"}/>*/}
            <Project className="home-four-project" />
            <Footer className="home-four-footer" />
            <BackToTop className="back-to-top-3" />
        </>
    );
};

export default Home;
