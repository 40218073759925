import React from 'react';
import {Navigate} from "react-router-dom";
import PaginaDocumenteAdmin from "./DocumenteIncarcateDeAntreprenori/PaginaDocumenteAdmin";

const ProtectedRouteAdmin = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaDocumenteAdmin />
    }
    return <Navigate to="/" />
};

export default ProtectedRouteAdmin;
