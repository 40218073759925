import React, {useEffect} from 'react';
import TopToBottom from "../lib/TopToBottom";
import {Link} from "react-router-dom";

const BackToTop = ({ className }) => {
    useEffect(() => {
        TopToBottom('.back-to-top');
    });
    return (
        <>
            <div className={`back-to-top ${className || ''}`}>
                <Link>
                    <i className="fal fa-arrow-up" />
                </Link>
            </div>
        </>
    );
};

export default BackToTop;
