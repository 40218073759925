import React from 'react';
import axiosInstance from "../Helper/AxiosHelper";
import FileDownload from "js-file-download";

const Intro = ({className}) => {

    function downloadFile(e) {
        e.preventDefault();
        let fileName = "";
        if (e.target.id === 'doc1') {
            fileName = "Comunicat_de_Presa_Eveniment_Harghita.docx";
        }
        else if (e.target.id === 'doc2') {
            fileName = "2.LISTA Rezultate finale Faza evaluare A_administrativ.pdf";
        }
        else if (e.target.id === 'doc3') {
            fileName = "3.LISTA Rezultate preliminare Faza B_tehnic si calitativ.pdf";
        }
        else if (e.target.id === 'doc4') {
            fileName = "4.LISTA FINALA.pdf";
        }
        else if (e.target.id === 'doc5') {
            fileName = "Procedura selectie PA_154951 final.pdf";
        }
        else if (e.target.id === 'doc6') {
            fileName = "Procedura semnare contract de subventie.pdf";
        }

        axiosInstance.get(`/api/cntr/files/downloads/${fileName}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, `${fileName}`)
        })
    }

    return (
        <>
        <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-download-3-box wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                            In aceasta sectiune puteti gasi diverse comunicari, noutati, stiri si informatii despre proiect. Aici este locul unde vom posta comunicate de presa, aparitiile noastre  in media, evenimente organizare prin care ne vom cunoaste, calendarul concursului de antreprenoriat urmat de publicarea listei de proiecte acceptate la finantare.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section className="appie-blog-3-area pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                Comunicat de presa - Eveniment Harghita
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc1" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                LISTA Rezultate finale Faza evaluare A_administrativ
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc2" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                LISTA Rezultate preliminare Faza B_tehnic si calitativ
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc3" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                LISTA FINALĂ A PLANURILOR DE AFACERI SELECTATE LA FINANȚARE
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc4" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                Procedura selectie PA_154951
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc5" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                Procedura semnare contract de subventie
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc6" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
    );
};

export default Intro;
