import React, {useEffect, useState} from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Noutati from "./Noutati";
import Intro from "./Intro";
import axiosInstance from "../Helper/AxiosHelper";
import NoutatiSideBar from "./NoutatiSideBar";
import {useAtom} from "jotai";
import {userAtom} from "../Login/Login";
import {FaPlusCircle} from "react-icons/fa";

const PaginaNoutati = () => {
    const [drawer, drawerAction] = useToggle(false);
    const [news, setNews] = useState([]);
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);

    // const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    async function fetchData() {
        axiosInstance.get('/api/cntr/news/all').then((response) => {
            setNews(response.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        fetchData();
        // axiosInstance.get('/api/news/all').then((response) => {
        //     setNews(response.data);
        //
        // })

    }, []);

    // news && news.forEach((element) => {
    //     fetchImages(element.id)
    // })
    // useEffect(() => {
    //     console.log("useeffect la imagini")
    //     news && news.forEach((element) => {
    //         fetchImages(element.id)
    //     })
    //     // setIsLoading(false)
    // }, [])
    //
    //
    // async function fetchImages(id) {
    //     axiosInstance.get('/api/newsimage/id', {
    //         params: {
    //             id: id
    //         }
    //     }).then(response => {
    //         setImages(images => [...images, response])
    //     }).catch((e) => console.log(0, e));
    //
    // }
    // const getAllNews = () => {
    //
    //     axiosInstance.get(`/api/cntr/news/all`).then((response) => {
    //         setNews(response.data);
    //     });
    // }
    // const createNewNews = async(e) => {
    //     e.preventDefault();
    //     const news = JSON.stringify({
    //         title : "Titlul noutatii",
    //         text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //         isPublic : false,
    //         format : "Format",
    //         newsType : "Tip noutate",
    //         isImage: false,
    //     })
    //     try {
    //         await axiosInstance.post('/api/cntr/news/add', news,{
    //             headers: {"Content-Type": "application/json"}
    //         })
    //
    //     } catch(error) {
    //         console.log(error)
    //     }
    //     getAllCourses();
    // }

    // const getAllCourses = () => {
    //     axiosInstance.get('/api/cntr/news/all').then((response) => {
    //         setNews(response.data);
    //     });
    // }
    if (isLoading) {
        return <div></div>;
    }
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Noutati'} activity={'noutati'}/>
            <Intro/>
            {/*{localStorage.getItem('role') === 'admin' && (*/}
            {/*    <div className="container" style={{ paddingRight: "3em", paddingTop: "30px"}}>*/}
            {/*        <div className="row" style={{ flexDirection: "row", justifyContent: "center" }}>*/}
            {/*            <div className="blog-sidebar" >*/}
            {/*                <aside className="widget widget-trend-post" onClick={createNewNews} style={{cursor:"pointer"}} >*/}
            {/*                    <div className="service-download-widget" style={{textAlign: 'center'}}>*/}
            {/*                        <h3><FaPlusCircle /> Adauga noutate</h3>*/}
            {/*                    </div>*/}
            {/*                </aside>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-7">
                            <Noutati news={news} setNews={setNews}/>
                        </div>
                        {/*<div className="col-lg-4 col-md-5">*/}
                            {/*<NoutatiSideBar news={news} setNews={setNews} images={images}/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaNoutati;
