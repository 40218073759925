import React, {useState} from 'react';
import thumb from '../../assets/images/meeting.jpg';
import shapeSix from '../../assets/images/shape/shape-6.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';
import {Link} from "react-router-dom";

const ActivitatiPrincipale = ({ className }) => {
    const [tab, setTab] = useState('setting');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (
        <section>
            <section className={`appie-features-area pt-100 ${className}`} id="features" style={{paddingBottom: '100px'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Activitatile din cadrul proiectului</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center" style={{paddingBottom: "20px"}}>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <p align="justify" style={{fontSize: '20px'}}>
                                <b>                                In vederea selectarii in grupul tinta,ne intereseaza tinerii cu varsta intre 16 si 29 de ani care nu sunt ocupati si nu urmeaza nicio forma de educatie sau formare care sunt inregistrati la nivelul SPO vor fi preluati in urma unei colaborari stranse intre structura de parteneriat si AJOFM-uri.
                                </b>
                            </p>
                            <p align="justify" style={{fontSize: '20px'}}>
                                <b>In cadrul proiectului se desfasoara urmatoarele activitati:</b>
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="appie-features-tabs-btn">
                                <div
                                    className="nav flex-column nav-pills"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <a
                                        onClick={(e) => handleClick(e, 'setting')}
                                        className={`nav-link ${tab === 'setting' ? 'active' : ''}`}
                                        id="v-pills-home-tab"
                                        data-toggle="pill"
                                        href="src/components/HomeOne/FeaturesHomeOne#v-pills-home"
                                        role="tab"
                                        aria-controls="v-pills-home"
                                        aria-selected="true"
                                    >
                                        <i className="fas fa-info" /> Activitate 1
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'report')}
                                        className={`nav-link ${tab === 'report' ? 'active' : ''}`}
                                        id="v-pills-profile-tab"
                                        data-toggle="pill"
                                        href="src/components/HomeOne/FeaturesHomeOne#v-pills-profile"
                                        role="tab"
                                        aria-controls="v-pills-profile"
                                        aria-selected="false"
                                    >
                                        <i className="fas fa-info" /> Activitate 2
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'notice')}
                                        className={`nav-link ${tab === 'notice' ? 'active' : ''}`}
                                        id="v-pills-messages-tab"
                                        data-toggle="pill"
                                        href="src/components/HomeOne/FeaturesHomeOne#v-pills-messages"
                                        role="tab"
                                        aria-controls="v-pills-messages"
                                        aria-selected="false"
                                    >
                                        <i className="fas fa-info" /> Activitate 3
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'app')}
                                        className={`nav-link ${tab === 'app' ? 'active' : ''}`}
                                        id="v-pills-settings-tab"
                                        data-toggle="pill"
                                        href="src/components/HomeOne/FeaturesHomeOne#v-pills-settings"
                                        role="tab"
                                        aria-controls="v-pills-settings"
                                        aria-selected="false"
                                    >
                                        <i className="fas fa-info" /> Activitate 4
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div
                                    className={`${
                                        tab === 'setting' ? 'show active' : ''
                                    } tab-pane fade`}
                                    id="v-pills-home"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-home-tab"
                                >
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div
                                                className="appie-features-content wow animated fadeInRight"
                                                data-wow-duration="2000ms"
                                                data-wow-delay="600ms"
                                            >
                                                <p align="justify" style={{fontSize: '20px'}}>
                                                    Derularea unei campanii de informare si promovare a proiectului in vederea identificarii grupului tinta
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${tab === 'report' ? 'show active' : ''} tab-pane fade`}
                                    id="v-pills-profile"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-profile-tab"
                                >
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div
                                                className="appie-features-content animated fadeInRight"
                                                data-wow-duration="2000ms"
                                                data-wow-delay="600ms"
                                            >
                                                <p align="justify" style={{fontSize: '20px'}}>
                                                    Furnizarea de programe de formare profesionala pentru tinerii Neets din categoriile B, C si D
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${tab === 'notice' ? 'show active' : ''} tab-pane fade`}
                                    id="v-pills-messages"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-messages-tab"
                                >
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div
                                                className="appie-features-content animated fadeInRight"
                                                data-wow-duration="2000ms"
                                                data-wow-delay="600ms"
                                            >
                                                <p align="justify" style={{fontSize: '20px'}}>
                                                    Furnizarea de servicii specializate pentru stimularea ocuparii fortei de munca si de ocupare pentru toate categoriile de tineri Neets vizati de proiect
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${tab === 'app' ? 'show active' : ''} tab-pane fade`}
                                    id="v-pills-settings"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-settings-tab"
                                >
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div
                                                className="appie-features-content animated fadeInRight"
                                                data-wow-duration="2000ms"
                                                data-wow-delay="600ms"
                                            >
                                                <p align="justify" style={{fontSize: '20px'}}>
                                                    Sustinerea antreprenoriatului, inclusiv a ocuparii pe cont propriu
                                                </p>
                                                <p align="justify" style={{fontSize: '20px'}}>
                                                    4.1 Acordarea de sprijin tinerilor Neets din grupul tinta cu nivel de ocupabilitate "A" pentru infiintarea unei afaceri
                                                </p>
                                                <p align="justify" style={{fontSize: '20px'}}>
                                                    4.2 Acordarea de subventii (microgranturi) pentru infiintarea de noi afaceri de catre tinerii Neets din grupul tinta cu nivel de ocupabilitate "A"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div
                                className="appie-features-thumb text-center animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={thumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src={shapeSix} alt="" />
                </div>
                <div className="features-shape-2">
                    <img src={shapeSeven} alt="" />
                </div>
                <div className="features-shape-3">
                    <img src={shapeEight} alt="" />
                </div>
            </section>
        </section>
    );
};

export default ActivitatiPrincipale;
