import React, {useEffect, useState} from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroResurseProiect from "./HeroResurseProiect";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import axiosInstance from "../Helper/AxiosHelper";
import ListaActivitati from "./ListaActivitati";
import {useAtom} from "jotai";
import {userAtom} from "../Login/Login";

const PaginaA2 = () => {
    const [drawer, drawerAction] = useToggle(false);
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/cntr/activity/a2/all').then((response) => {
            setActivities(response.data);
        })
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Resurse pentru A.2. Furnizarea de programe de formare profesionala'} activity={'A2'}/>
            <ListaActivitati activities={activities} setActivities={setActivities} activityType={'a2'}/>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaA2;
