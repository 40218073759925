import React, {useEffect} from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import '../../assets/css/parser.css'
import {FiEdit} from "react-icons/fi";

// const CODURISV = {
//     'DJ': 16,
//     'MH': 18,
//     'VL': 28,
//     'OT': 73,
//     'GJ': 69,
// }

const CODURISV = {
    'AB': 1,
    'BV': 9,
    'CV': 23,
    'HR': 29,
    'MS': 35,
    'SB': 41,
}

const JoburiAuto = () => {
    const [queryParams, setQueryParams] = useState({
        page: 1,
        pageSize: 10,
        q: '',
        sort: 'date',
    });

    // const [counties, setCounties] = useState([`DJ`, `MH`, `VL`, `OT`, `GJ`])
    const [counties, setCounties] = useState([`AB`, `BV`, `CV`, `HR`, `MS`, `SB`])
    const [isCountyVisible, setIsCountyVisible] = useState(false);

    function toggleCounties(county) {
        const result = [...counties]
        const index = counties.indexOf(county)
        if (index === -1) result.push(county)
        else result.splice(index, 1)
        setCounties(result)
    }

    const [resp, setResp] = useState(null);

    function fetchData() {
        setTags([])
        setIsCountyVisible(false);
        var url = new URL('https://api.ejobs.ro/jobs');
        const searchParams = new URLSearchParams(queryParams)
        searchParams.append('filters.careerLevels', 10)

        counties.forEach(county => Object.keys(CODURISV).indexOf(county) > -1 && searchParams.append(`filters.cities`, CODURISV[county]))

        url.search = searchParams.toString();
        fetch(url.toString())
            .then((res) => res.json())
            .then((response) => {

                setResp(response.jobs)
                setIsCountyVisible(true);
                // fetchMetaTags()
            })
            .catch((e) => console.log(0, e));

    }

    const [tags, setTags] = useState([]);

    useEffect(() => {
        resp &&
        resp.forEach((element) => {
            fetchMetaTags(element.id)
        })
        async function fetchMetaTags(id) {
            var url = new URL(`https://api.ejobs.ro/jobs/${id}`);
            url.search = new URLSearchParams({ viewedFromMobile: false }).toString();
            fetch(url.toString())
                .then((res) => res.json())
                .then((response) => {
                    setTags(tags => [...tags, response])
                    console.log(response)
                })
                .catch((e) => console.log(0, e));
        }

    }, [resp])


    // useEffect(() => fetchData(), [])

    var rowNames = new Set();

    resp &&
    resp.forEach((element) => {
        Object.keys(element).forEach((individualKey) => rowNames.add(individualKey));
    });

    const handleChange = (event) => {
        setQueryParams({ ...queryParams, [event.target.id]: event.target.value });
        console.log(queryParams);
    };

    const TableData = [];
    let currentPos = [];
    resp &&
    resp.forEach((position) => {
        currentPos = [];
        rowNames.forEach((rowName) => currentPos.push(position[rowName]));
        // console.log(currentPos)
        TableData.push(currentPos);
    });
    // console.log(TableData);
    // console.log(counties)
    return (
        <>
            <section className="appie-blog-3-area appie-blog-8-area pt-90 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Card style={{ marginTop: "25px", alignItems: 'center', textAlign: 'center'}}>
                                <Card.Body>
                                    {/*<Row >*/}
                                    {/*    <Col >*/}
                                    {/*        <Form >*/}
                                    {/*            <Form.Group className="mb-3" controlId="page" >*/}
                                    {/*                <Form.Label>Page No</Form.Label>*/}
                                    {/*                <Form.Control*/}
                                    {/*                    type="text"*/}
                                    {/*                    value={queryParams.page}*/}
                                    {/*                    onChange={handleChange}*/}
                                    {/*                />*/}
                                    {/*                <Form.Text className="text-muted"></Form.Text>*/}
                                    {/*            </Form.Group>*/}
                                    {/*        </Form>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <Col className="mb-3">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="q">
                                                    <Form.Label>Introdu cuvant cheie pentru cautare (Ex: ospatar)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={queryParams.q}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Text className="text-muted"></Form.Text>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3">
                                            Judetele mentionate sunt incluse in modul implicit.
                                            Poti exclude un judet din cautare, apasandu-l.
                                        </Col>
                                    </Row>
                                    <Row>
                                        {Object.keys(CODURISV).map(key =>
                                            <Col>
                                                <Button id="tbg-btn-1" value={key} key={key} onClick={() => toggleCounties(key)} variant={counties.indexOf(key) === -1 ? "secondary" : "primary"}>
                                                    {key}
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row style={{paddingTop: '30px'}}>
                                        <Col>
                                            <Button onClick={() => fetchData()}>Cauta job-uri</Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
            {isCountyVisible && (
                <section className="appie-blog-3-area appie-blog-8-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6" >
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/*<div className="appie-blog-item-3 appie-blog-item-8">*/}
                                            <div className="content">
                                                <h5 className="title" align="center">
                                                    Vezi mai jos job-urile gasite din judetele:<br/>
                                                    {counties.map((county, index) => {
                                                        return (
                                                            <b key={index}>{county}&nbsp;&nbsp;&nbsp;</b>
                                                        )
                                                    })}
                                                </h5>
                                            </div>
                                        {/*</div>*/}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            )}


            <section className="appie-blog-3-area appie-blog-8-area pt-90 pb-100">
                <div className="container">
                    <div className="row">
                        {tags.map((job) => {
                            return (
                                <>
                                    <div className="col-lg-12" key={job.id}>
                                        <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                                            <div className="content">
                                                <h5 className="title">
                                                    {job.title}
                                                </h5>
                                                <p align="justify">
                                                    {job.details.metaTags}
                                                </p>
                                                <div>
                                                    <h5 >
                                                        <a href={`https://www.ejobs.ro/user/locuri-de-munca/${job.slug}/${job.id}`} target="_blank">
                                                            Vezi detalii&nbsp;&nbsp;
                                                            <i className="fal fa-long-arrow-right"></i>
                                                        </a>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};

export default JoburiAuto;
