import React from 'react';
import {Link, useHistory, useNavigate} from "react-router-dom";
import Scroll, {scroller} from 'react-scroll';

const ScrollLink = Scroll.Link

const Navigation = () => {
    const navigate = useNavigate();
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 0, spy: true});
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const scrollToHomePage = async (target) => {
        if (window.location.pathname !=='/') {
            await navigate('/');
            await delay(100);
        }
        scrollTarget(target);
    };

    const scrollToPage = async (target) => {
        if (window.location.pathname !=='/concurs-plan-de-afaceri') {
            await navigate('/concurs-plan-de-afaceri');
            await delay(100);
        }
        scrollTarget(target);
    };
    return (
        <>
            <ul>
                <li>
                    <Link to="/">
                        Despre proiect<i className="fal fa-angle-down" />
                    </Link>
                    <ul className="sub-menu" style={{maxWidth: '350px', minWidth: '350px'}}>
                        <li onClick={() => scrollToHomePage('sumar-proiect')}>
                            <Link>Sumar proiect</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('campanie-de-informare')}>
                            <Link>Grup tinta</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('selectare-si-inscriere')}>
                            <Link>Obiectivele proiectului</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('activitati-principale')}>
                            <Link>Activitatile din cadrul proiectului</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('grupul-tinta')}>
                            <Link>Parteneriate</Link>
                        </li>


                        {/*<li onClick={() => scrollToHomePage('formare-profesionala')}>*/}
                        {/*    <Link>Formare profesionala</Link>*/}
                        {/*</li>*/}
                        {/*<li onClick={() => scrollToHomePage('medierea-muncii')}>*/}
                        {/*    <Link>Medierea muncii</Link>*/}
                        {/*</li>*/}
                    </ul>
                </li>
                <li>
                    <Link>
                        Resurse proiect <i className="fal fa-angle-down" />
                    </Link>
                    <ul className="sub-menu" style={{maxWidth: '300px', minWidth: '300px'}}>
                        <li>
                            <Link to="/activitate1">Activitate A1</Link>
                        </li>
                        <li>
                            <Link to="/activitate2">Activitate A2</Link>
                        </li>
                        <li>
                            <Link to="/activitate3">Activitate A3</Link>
                        </li>
                        <li>
                            <Link to="/activitate4">Activitate A4</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/cursuri">
                        Cursuri
                    </Link>
                </li>

                <li>
                    <Link to="/joburi">Job-uri</Link>
                </li>
                <li>
                    <Link to="/noutati">Noutati</Link>
                </li>
                {/*<li>*/}
                {/*    <Link to="/monitorizare-proiect">Monitorizare proiect</Link>*/}
                {/*</li>*/}
                <li>
                    <Link to="/contact" >Contact</Link>
                </li>
                {localStorage.getItem('role') === 'user' && (
                        <li>
                            <Link to="/incarca-plan-de-afaceri" style={{color: 'black'}}>Incarca Plan Afaceri</Link>
                        </li>
                )}
                {localStorage.getItem('role') === 'admin' && (
                    <li>
                        <Link to="/documente-admin" style={{color: 'black'}}>Documente Admin</Link>
                    </li>
                )}
            </ul>

        </>
    );
};

export default Navigation;
