import React, {useEffect, useState} from 'react';
import {GiPlainCircle} from "react-icons/gi";
import Quill from "../../lib/Quill";
import axiosInstance from "../Helper/AxiosHelper";
import {Link} from "react-router-dom";
import {FiEdit} from "react-icons/fi";
import {FaPlusCircle} from "react-icons/fa";
import FileDownload from "js-file-download";

const ListaActivitati = ({activities, setActivities, activityType}) => {

    const [activityFiles, setActivityFiles] = useState([]);

    async function fetchActivitiesFilesByActivityId(id) {
        await axiosInstance.get('/api/cntr/activityfile/id', {
            params: {
                id: id
            },
            // responseType:"blob"
        }).then(r => {
            const response = r.data;
            response.forEach((element) => {
                if (activityFiles.length === 0) {
                    response && setActivityFiles(activityFiles => [...activityFiles, element]);
                }
                response && activityFiles.some(e => e === element) &&
                setActivityFiles(activityFiles => [...activityFiles, element]);
            })

            // setLoading(false);
        })
    }

    useEffect(() => {
        activities &&
        activities.forEach((element) => {
            if (element.isFile) {
                fetchActivitiesFilesByActivityId(element.id)
            }
        })
    },[activities]);

    const addNewActivity= async(e) => {
        e.preventDefault();
        const activity = JSON.stringify({
            title: "Titlu activitate",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic: false,
            type: activityType
        })
        try {

            const response = await axiosInstance.post('/api/cntr/activity/add', activity, {
                headers: { "Content-Type": "application/json" }
            })
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllActivities();
    }

    const getAllActivities = () => {

        axiosInstance.get(`/api/cntr/activity/${activityType}/all`).then((response) => {
            console.log(response.data)
            setActivities(response.data);
        });
    }

    const downloadFile = (file) => (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/cntr/activityfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, file.file, file.type)
        })
    }

    return (
        <>
            {localStorage.getItem('role') === 'admin' && (
                <div className="container" style={{ paddingRight: "3em", paddingTop: "30px"}}>
                    <div className="row" style={{ flexDirection: "row", justifyContent: "center" }}>
                        <div className="blog-sidebar" >
                            <aside className="widget widget-trend-post" onClick={addNewActivity} style={{cursor:"pointer"}} >
                                <div className="service-download-widget" style={{textAlign: 'center'}}>
                                    <h3><FaPlusCircle /> Adauga activitate</h3>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            )}
        {activities.map((activity) => {
            return (
                <>
                {localStorage.getItem("role") === "admin" && (
                    <section className="appie-blog-3-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="appie-blog-item-3 mt-30">
                                        <div className="content">
                                            <div className="row">
                                                <Link to={"/activitate?id=" + activity.id}>
                                                    <FiEdit />
                                                </Link>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="appie-section-title text-center" >
                                                        <h3 className="appie-title">{activity.title}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-justify" dangerouslySetInnerHTML={{__html: activity.text}}>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="content text-justify">
                                                    <br/>
                                                    <ul>
                                                        {activityFiles.map((file, index) => {
                                                            if (activity.id === file.activity.id) {
                                                                return(
                                                                    <li key={index} onClick={downloadFile(file)} style={{cursor:"pointer"}}>
                                                                        <b><i className="fal fa-tag"></i>{file.file}</b>
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                    {activity.isPublic && localStorage.getItem("role") !== "admin" && (
                        <section className="appie-blog-3-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-blog-item-3 mt-30">
                                            <div className="content">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="appie-section-title text-center" >
                                                            <h3 className="appie-title">{activity.title}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row text-justify" dangerouslySetInnerHTML={{__html: activity.text}}>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="content text-justify">
                                                        <br/>
                                                        <ul>
                                                            {activityFiles.map((file, index) => {
                                                                if (activity.id === file.activity.id) {
                                                                    return(
                                                                        <li key={index} onClick={downloadFile(file)} style={{cursor:"pointer"}}>
                                                                            <b><i className="fal fa-tag"></i>{file.file}</b>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </>
            )
            })}

        </>
    );
};

export default ListaActivitati;
