import React from 'react';
import FileDownload from "js-file-download";
import axiosInstance from "../Helper/AxiosHelper";

const SectiuniDocumente = ({users}) => {
    const downloadFile = (user) => (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/cntr/files/download/${user.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(user)
            const file = res.data;
            FileDownload(file, user.fileName, file.type)
        })
    }


    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Documente incarcate</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {users.map((user) => {
                        return (
                                <div className="col-lg-6" key={user.id}>
                                    <div className="appie-blog-item-3">
                                        <div className="content">
                                            <div className="row">
                                                <h3 className="appie-title">#{user.id} {user.lastName} {user.firstName}</h3>
                                            </div>
                                            <div className="row align-items-center ">
                                                <ul>
                                                    <li>
                                                        {user.isBusinessPlan && (
                                                            <label onClick={downloadFile(user)} style={{cursor:"pointer"}}>
                                                                <i className="fal fa-download"></i> Descarca planul de afaceri
                                                            </label>
                                                        )}
                                                        {!user.isBusinessPlan && (
                                                            <label>
                                                                <i className="fal fa-times-hexagon"></i> Nu exista documente incarcate
                                                            </label>
                                                        )}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    })}

                </div>
            </div>
        </section>
    );
};

export default SectiuniDocumente;
