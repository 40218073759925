import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import blogImg1 from "../../assets/images/blog/1.jpg";
import axiosInstance from "../Helper/AxiosHelper";
import {FaPlusCircle} from "react-icons/fa";
import { baseURL } from '../Helper/AxiosHelper'

const Noutati = ({news, setNews}) => {
    const [images, setImages] = useState([]);
    // useEffect(() => {
    //     news && news.forEach((element) => {
    //         if (element.isImage) {
    //             fetchImages(element.id)
    //         }
    //     })
    //
    //     // setIsLoading(false)
    // }, [news])

    console.log(news)

    async function fetchImages(id) {
        axiosInstance.get('/api/cntr/newsimage/id', {
            params: {
                id: id
            }
        }).then(response => {
            if (!(response.status === 200)) {
                throw new Error(`Error! status: ${response.status}`);
            }
            if (images.length === 0) {
                response.data && setImages(images => [...images, response.data])
            }
            response.data && images.some(e => e === response.data) &&
            setImages(images => [...images, response.data])
        }).catch((e) => console.log(0, e));

    }
    const createNewNews = async(e) => {
        e.preventDefault();
        const news = JSON.stringify({
            title : "Titlul noutatii",
            text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic : false,
            format : "Format",
            newsType : "Tip noutate"
        })
        try {
            await axiosInstance.post('/api/cntr/news/add', news,{
                headers: {"Content-Type": "application/json"}
            })

        } catch(error) {
            console.log(error)
        }
        getAllCourses();
    }

    const getAllCourses = () => {
        axiosInstance.get('/api/cntr/news/all').then((response) => {
            setNews(response.data);
        });
    }

    function fetchImage(course) {
        axiosInstance.get('/api/cntr/newsimage/id', {
            params: {
                id: course.id
            }
        }).then(response => {
            // setImages(images => [...images, response.data])
            return `data:${response.data.type};base64,${response.data.data}`;
        }).catch((e) => console.log(0, e));

    }

    return (
        <div id="articles" className="row">
            {localStorage.getItem('role') === 'admin' && (
                <div className="container" style={{ paddingRight: "3em", paddingTop: "30px"}}>
                    <div className="row" style={{ flexDirection: "row", justifyContent: "center" }}>
                        <div className="blog-sidebar" >
                            <aside className="widget widget-trend-post" onClick={createNewNews} style={{cursor:"pointer"}} >
                                <div className="service-download-widget" style={{textAlign: 'center'}}>
                                    <h3><FaPlusCircle /> Adauga noutate</h3>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            )}
            {news.map((course) => {
                return (
                    <>
                        {course.isPublic && localStorage.getItem('role') !== 'admin' && (

                            <div className="col-lg-4">
                                <div className="post-item-1" >
                                    {!course.isImage && (
                                        <img src={blogImg1} alt="" style={{height: '300px'}} loading="lazy"/>
                                    )}
                                    {course.isImage && (
                                        <img src={`${baseURL}/api/cntr/newsimage/${course.id}`} alt="" style={{height: '300px'}} loading="lazy"/>
                                    )}

                                    <div className="b-post-details">
                                        <h3>
                                            {/*<a href={"/material?id=" + course.id}>*/}
                                            {/*    {course.title}*/}
                                            {/*</a>*/}
                                            <Link to={"/noutate?id=" + course.id}>
                                                {course.title}
                                            </Link>
                                        </h3>
                                        <a className="read-more" href={"/noutate?id=" + course.id}>
                                            Vezi detalii<i className="fal fa-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="col-lg-4" key={course.id}>
                                <div className="post-item-1" id={"noutate" + course.id}>

                                    {!course.isImage && (
                                        <img src={blogImg1} alt="" style={{height: '300px'}}/>
                                    )}

                                    {course.isImage && (
                                        <img src={`${baseURL}/api/cntr/newsimage/${course.id}`} alt="" style={{height: '300px'}} loading="lazy"/>
                                    )}
                                    <div className="b-post-details">
                                        <h3>
                                            <Link to={"/noutate?id=" + course.id}>
                                                {course.title}
                                            </Link>
                                        </h3>
                                        <a className="read-more" href={"/noutate?id=" + course.id}>
                                            Vezi detalii<i className="fal fa-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}

                    </>

                )
            })}
        </div>
    );
};

export default Noutati;
