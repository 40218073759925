import React, {useEffect} from 'react';
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import Home from "./components/Home/Home";
import ScrollToTop from "./components/Helper/ScrollToTop";
// import PaginaArticole from "./components/Articole/PaginaArticole";
// import PaginaArticol from "./components/Articole/PaginaArticol";
// import PaginaConcurs from "./components/Concurs/PaginaConcurs";
// import PaginaContact from "./components/Contact/PaginaContact";
// import PaginaCurs from "./components/Cursuri/PaginaCurs";
// import PaginaCursuri from "./components/Cursuri/PaginaCursuri";
// import PaginaJoburi from "./components/PaginaJoburi/PaginaJoburi";
// import PaginaLogin from "./components/Login/PaginaLogin";
// import PaginaParteneri from "./components/Parteneri/PaginaParteneri";
// import PaginaConcursPlanDeAfaceri from "./components/ConcursPlanDeAfaceri/PaginaConcursPlanDeAfaceri";
// import PaginaFirme from "./components/Firme/PaginaFirme";
// import PaginaNoutati from "./components/Noutati/PaginaNoutati";
// import PaginaFirma from "./components/Firme/PaginaFirma";
// import PaginaMaterialeSuport from "./components/MaterialeSuport/PaginaMaterialeSuport";
// import CalendarConcurs from "./components/ConcursPlanDeAfaceri/CalendarConcurs";
// import ListaCastigatori from "./components/ConcursPlanDeAfaceri/ListaCastigatori";
// import PaginaMonitorizareProiect from "./components/MonitorizareProiect/PaginaMonitorizareProiect";
// import PaginaIncarcaPlanDeAfaceri from "./components/IncarcarePlanDeAfaceri/PaginaIncarcaPlanDeAfaceri";
// import PaginaDocumenteAdmin from "./components/DocumenteIncarcateDeAntreprenori/PaginaDocumenteAdmin";
import ProtectedRouteUser from "./components/ProtectedRouteUser";
import ProtectedRouteAdmin from "./components/ProtectedRouteAdmin";
import PaginaA1 from "./components/ResurseProiect/PaginaA1";
import PaginaA2 from "./components/ResurseProiect/PaginaA2";
import PaginaA3 from "./components/ResurseProiect/PaginaA3";
import PaginaA4 from "./components/ResurseProiect/PaginaA4";
import PaginaCursuri from "./components/Cursuri/PaginaCursuri";
import PaginaJoburi from "./components/Joburi/PaginaJoburi";
import PaginaNoutati from "./components/Noutati/PaginaNoutati";
import PaginaLogin from "./components/Login/PaginaLogin";
import PaginaNoutate from "./components/Noutati/PaginaNoutate";
import ProtectedJobRouteAdmin from "./components/ProtectedJobRouteAdmin";
import JoburiAuto from "./components/Joburi/JoburiAuto";
import Individual from "./components/Joburi/Individual";
import ProtectedActivitateRouteAdmin from "./components/ProtectedActivitateRouteAdmin";
import PaginaContact from "./components/Contact/PaginaContact";

const AppRoutes = () => {
    // const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);
    // });
    return (
        <>
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={ <Home/> } />
                        <Route path="/activitate1" element={ <PaginaA1/> } />
                        <Route path="/activitate2" element={ <PaginaA2/> } />
                        <Route path="/activitate3" element={ <PaginaA3/> } />
                        <Route path="/activitate4" element={ <PaginaA4/> } />
                        <Route path="/activitate" element={ <ProtectedActivitateRouteAdmin/> } />
                        <Route path="/cursuri" element={ <PaginaCursuri/> } />
                        <Route path="/joburi" element={ <PaginaJoburi/> } />
                        <Route path="/noutati" element={ <PaginaNoutati/> } />
                        <Route path="/noutate" element={ <PaginaNoutate/> } />
                        <Route path="/job" element={ <ProtectedJobRouteAdmin/> } />
                        <Route path="/joburi-auto" element={ <JoburiAuto/> } />
                        <Route path="/anunt/:id" element={<Individual />} />
                        <Route path="/contact" element={<PaginaContact/>} />
                        <Route path="/login" element={<PaginaLogin/>} />
                        <Route path="/incarca-plan-de-afaceri" element={<ProtectedRouteUser />} />
                        <Route path="/documente-admin" element={<ProtectedRouteAdmin/>} />
                        <Route path="/*" element={<Home/>} />
                    </Routes>
                </ScrollToTop>
            </Router>
            {/*</div>*/}
        </>
    );
};

export default AppRoutes;
