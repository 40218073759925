import React from 'react';
import {Link} from "react-router-dom";
import FileDownload from "js-file-download";
import axiosInstance from "../Helper/AxiosHelper";

const IntroA4 = () => {

    function downloadFile(e) {
        e.preventDefault();
        let fileName = "";
        console.log(e.target.id)
        if (e.target.id === 'doc1') {
            fileName = "Procedura selectie PA_154951 final.pdf";
        }
        else if (e.target.id === 'doc2') {
            fileName = "Procedura semnare contract de subventie.pdf";
        }
        else {
            fileName = e.target.innerText;
        }

        axiosInstance.get(`/api/cntr/files/downloads/${fileName}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, `${fileName}`)
        })
    }


    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="col-lg-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-2">
                                        <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                    </div>
                                    <div className="col-lg-8">
                                        {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                        <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                            Procedura selectie PA_154951
                                        </p>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="content" >
                                            <button className="main-btn" id="doc1" onClick={downloadFile}>
                                                <i className="fal fa-download" />Descarca
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="appie-blog-item-3 mt-30">
                            <div className="col-lg-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-2">
                                        <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                    </div>
                                    <div className="col-lg-8">
                                        {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                        <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                            Procedura semnare contract de subventie
                                        </p>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="content" >
                                            <button className="main-btn" id="doc2" onClick={downloadFile}>
                                                <i className="fal fa-download" />Descarca
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="row" style={{paddingTop: '40px'}}>
            <div className="col-lg-6">
                <div className="col-lg-12">
                    <div className="appie-how-it-work-content">
                        <div className="row" >
                            <div className="col-sm-12">
                                <p align="justify" style={{fontSize: '24px'}}>
                                    Concursul de planuri de afaceri este deschis <b>EXCLUSIV</b> persoanelor inscrise in grupul tinta al
                                    proiectului, care indeplinesc cumulativ urmatoarele cerinte:
                                </p>
                            </div>
                            <div className="col-sm-12">
                                <div className="how-it-work-box" >
                                    <span><i className="fas fa-users" /></span>
                                    <p align="justify">
                                        Se incadreaza in categoria de grup tinta eligibil.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="how-it-work-box" >
                                    <span><i className="fas fa-users" /></span>
                                    <p align="justify">
                                        Intocmesc si depun dosar de candidatura in cadrul concursului de planuri de afaceri,
                                        plan care nu a mai fost finantat din alte surse provenite din Fondul Social European
                                        (pentru evitarea dublei finantari).
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="how-it-work-box" >
                                    <span><i className="fas fa-users" /></span>
                                    <p align="justify">
                                        In cazul finantarii planului de afaceri, isi asuma ca va fi reprezentantul legal al
                                        intreprinderii legal constituite in România, fiind direct responsabili de pregatirea si
                                        implementarea planului de afaceri si neactionand ca intermediar pentru planul de
                                        afaceri propus a fi finantat.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="team-btn text-center mt-50" >
                                    <Link to="/login">
                                        <button className="main-btn" >
                                            <i className="fal fa-file-pdf"></i> Incarca planul tau de afaceri
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="service-details-sidebar mr-50">
                    <div className="service-category-widget">
                        <ul>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>Anexa 1 PA_Coduri CAEN Eligibile.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 2_Buget_154951.xlsx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 3_Previziuni Financiare 154951.xls
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 4 Scrisoare inaintare.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>Anexa 5 Declaratie eligibilitate si inscriere in competitie.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>Anexa 6 Evitarea dublei finantari.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>Anexa 7 Declaratie conflict de interese.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 8 Declaratie de Angajament.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 9 Condamnari si cazier.doc
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>Anexa 10 Schema de minimis NEETS I.pdf
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>Anexa 11 GS CS Viitor pentru tineri NEET s I 2020.pdf
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 12_ Grila Evaluare administrativa si eligibilitate.docx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 13 Evaluare tehnica si calitativa.xlsx
                                </label>
                            </li>
                            <li>
                                <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                    <i className="fal fa-download"></i>ANEXA 14 Model Contestatie.doc
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </section>
    );
};

export default IntroA4;
