import React from 'react';

const Forms = () => {
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Gaseste informatii</h3>
                                <p>Cauti ajutor? Completeaza formularul si gaseste sprijinul dorit.</p>
                                <div className="single-info">
                                    <h5>Sediu</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        Strada Traian 122- 124,
                                        <br /> Bucuresti 030576
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Numar telefon</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        0722 603 820
                                        <br />
                                        0724 484 965
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>E-mail</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        neetnomorecentru@gmail.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Formular contact</h4>
                                {/*<form action="#" method="post" className="row">*/}
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="f-name" placeholder="Nume" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="l-name" placeholder="Prenume" />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="E-mail"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Numar telefon"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="suject" placeholder="Subiect" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="Cum te putem ajuta?"
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                Sunt de acord cu <a href="#">Termenii si conditiile</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </div>

                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.0460607473397!2d26.11864001590404!3d44.43221737910229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff4536d090e1%3A0x293f77cc27e82ea6!2sGood%20Story!5e0!3m2!1sro!2sro!4v1667564811248!5m2!1sro!2sro"
                    width="600"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                />
            </div>
        </>
    );
};

export default Forms;
