import React from 'react';
import thumbA1 from '../../assets/images/young-brunette-white-casual-sweater-isolated-purple-wall.jpg';
import thumbA2 from '../../assets/images/studio-shot-good-looking-young-businesswoman-posing-against-pink-wall-with-glasses.jpg';
import thumbA3 from '../../assets/images/serious-hipster-rubs-head-looks-seriously-colorful-notes-stuck-wall-recalls-necessary-information.jpg';
import thumbA4 from '../../assets/images/happy-businessman-dancing-rejoice-with-smartphone-listening-music-wireless-earphones.jpg';
import thumbCursuri from '../../assets/images/wepik-photo-mode-20221022-135518.png';
import thumbJoburi from '../../assets/images/wepik-photo-mode-20221022-135959.png';



const HeroResurseProiect = ({title, activity, pic}) => {
    return (
        <>
            <div className={`appie-page-title-area appie-page-service-title-area ${activity}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item">
                                <div className="col-lg-6">
                                    {title === 'Resurse pentru A.4. Curs de antreprenoriat si acordarea de subventii' && (
                                        <h3 className="title" style={{color: 'gray'}}>
                                            {title}
                                        </h3>
                                    )}
                                    {title !== 'Resurse pentru A.4. Curs de antreprenoriat si acordarea de subventii' && (
                                        <h3 className="title">
                                            {title}
                                        </h3>
                                    )}
                                </div>
                                    {pic === 'cursuri' && (
                                        <div className="thumb">
                                            <img src={thumbCursuri} loading="lazy" alt="" width="450" height="400"/>
                                        </div>
                                    )}
                                    {pic === 'joburi' && (
                                        <div className="thumb">
                                            <img src={thumbJoburi} loading="lazy" alt="" width="450" height="400"/>
                                        </div>
                                    )}
                                    {/*{activity === 'A2' && (*/}
                                    {/*    <img src={thumbA2} alt="" width="450" height="400"/>*/}
                                    {/*)}*/}
                                    {/*{activity === 'A3' && (*/}
                                    {/*    <img src={thumbA3} alt="" width="450" height="400"/>*/}
                                    {/*)}*/}
                                    {/*{activity === 'A4' && (*/}
                                    {/*    <img src={thumbA4} alt="" width="450" height="400"/>*/}
                                    {/*)}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroResurseProiect;
