import React, {useEffect, useState} from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Joburi from "./Joburi";
import axiosInstance from "../Helper/AxiosHelper";
import JoburiAuto from "./JoburiAuto";

const PaginaJoburi = () => {
    const [drawer, drawerAction] = useToggle(false);
    const [jobs, setJobs] = useState([]);
    const [isJoburiAuto, setIsJoburiAuto] = useState(false);

    useEffect(() => {
        axiosInstance.get('/api/cntr/job/all').then((response) => {
            setJobs(response.data);
        })
    }, []);

    function showJoburiAuto() {
        setIsJoburiAuto(!isJoburiAuto);
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Cauti job-ul potrivit pentru tine?'} activity={'joburi'}/>
            <Joburi jobs={jobs} setJobs={setJobs} />
            <div className="team-btn text-center mt-50 mb-50">
                <a className="main-btn" onClick={showJoburiAuto}>
                    <i className="fal fa-briefcase"></i> Vezi mai multe job-uri
                </a>
            </div>
            {isJoburiAuto && (
                <JoburiAuto />
            )}
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaJoburi;
