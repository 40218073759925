import React from 'react';
import PaginaDocumenteAdmin from "./DocumenteIncarcateDeAntreprenori/PaginaDocumenteAdmin";
import {Navigate} from "react-router-dom";
import PaginaJobForEdit from "./Joburi/PaginaJobForEdit";

const ProtectedJobRouteAdmin = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaJobForEdit />
    }
    return <Navigate to="/joburi" />
};

export default ProtectedJobRouteAdmin;
