import React from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroResurseProiect from "../ResurseProiect/HeroResurseProiect";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Cursuri from "./Cursuri";

const PaginaCursuri = () => {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroResurseProiect title={'Cursuri de calificare'} activity={'cursuri'}/>
            <Cursuri />
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaCursuri;
