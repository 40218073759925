import React from 'react';
import {Link, Navigate} from "react-router-dom";
import PaginaIncarcaPlanDeAfaceri from "./IncarcarePlanDeAfaceri/PaginaIncarcaPlanDeAfaceri";

const ProtectedRouteUser = () => {
    if (!localStorage.getItem('user') || localStorage.getItem('role') === 'admin') {
        return <Navigate to="/" />
    }
    return <PaginaIncarcaPlanDeAfaceri />
};

export default ProtectedRouteUser;
