import React from 'react';

const Services = ({ className }) => {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                Sumar proiect
                            </h3>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                    Proiectul „Dezvoltarea Economiei din regiunea Centru prin implicarea tinerilor NEETs in societate – NEET TO MORE” este implimentat de S.C. WARD ASHBY STUDIO S.R.L., in calitate de beneficiar, in parteneriat cu compania de consultanta in accesare de finantari nerambursabile GRANT BOX CONSULTING S.R.L.
                                </p>
                                <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                    Proiectul are cod SMIS 154951 si este cofinantat din Fondul Social European prin Programul Operational Capital Uman - POCU 2014-2020, Axa prioritara 1, Initiativa „Locuri de munca pentru tineri”/Operatiune compozita OS. 1.1, 1.2.
                                </p>
                                <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                    Valoarea totala a contractului de finantare este de 4.926.999,95 lei din care finantarea nerambursabila totala este de 4.645.420,14 lei, echivalenta cu 96,93% din valoarea totala
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                    eligibila aprobata. Proiectul se desfasoara in regiunea Centru pana la data de 31.12.2023 si are ca grup tinta 371 de persoane, respectiv tineri din categoria NEETs: Not in Employment, Education or Training.
                                </p>
                                <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                    Acesta vizeaza promovarea unei ocupari durabile si de calitate a fortei de munca si integrarea consolidata pe piata muncii a tinerilor NEETs. Astfel, proiectul contribuie la realizarea obiectivului specific POCU prin dezvoltarea resurselor umane pe baza cresterii accesului tinerilor NEETs la programe de formare profesionala de calitate.
                                </p>
                                <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                    <b>Proiectul se implementeaza in regiunea de dezvoltare Centru, respectiv in judetele Alba, Brasov, Covasna, Harghita, Mures si Sibiu.</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
