import React from 'react';
import aboutThumb from "../../assets/images/about-thumb-2.png";
import aboutThumb3 from "../../assets/images/about-thumb-3.png";

const CampanieDeInformareSiPromovare = ({ className }) => {
    return (
        <>
            <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download" style={{paddingBottom: '20px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Grup Tinta</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-download-3-box wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="content">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                371 de tineri NEETs nivel de ocupabilitate A, B, C si D cu varsta intre 16 si 29 de ani inregistrati si profilati la SPO (Serviciul Public de Ocupare), cu rezidenta in regiunea de dezvoltare Centru, cu accent pe tinerii cu domiciliul in mediul rural (minim 75 persoane, 20%), pe tinerii apartinand minoritatii roma (minim 38 persoane, 10%).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CampanieDeInformareSiPromovare;
