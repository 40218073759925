import React from 'react';
import BlogFour from "../../assets/images/blog-4.jpg";
import BlogFive from "../../assets/images/blog-5.jpg";
import BlogSix from "../../assets/images/blog-6.jpg";
import BlogSeven from "../../assets/images/blog-7.jpg";
import {Link} from "react-router-dom";

const SelectareSiInscriereGrupTinta = () => {
    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Obiectivele proiectului</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="content">
                                <div className="row align-items-center">
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        <b>OBIECTIVUL GENERAL</b> al proiectului consta in dezvoltarea si implementarea unui ansamblu de masuri si actiuni concrete privind cresterea nivelului de ocupare si imbunatatirea competentelor pentru 371 de tineri NEETs.
                                    </p>
                                    <br/>
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        Astfel, se vizeaza imbunatatirea nivelului de competente profesionale relevante pentru piata muncii pentru 186 de tineri NEETs B, C si D, stimularea ocuparii sustenabile pe piata muncii a 371 de tineri NEETs A, B, C si D si ocuparea efectiva a cel putin 160 de tineri NEETs, stimularea demararii unei activitati independente pentru 15 tineri NEETs nivel ocupabilitate A cu valente antreprenoriale finantati cu subventii (micro-grant-uri) de maximum 25.000 Euro/subventie.
                                    </p>
                                    <br/>
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        <b>CONTRIBUTIA PROIECTULUI LA REALIZAREA OBIECTIVELOR SPECIFICE ALE APELULUI DE PROIECTE Viitor pentru tinerii NEETS I, respectiv OS1.1 si OS1.2 vizeaza: </b>
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row" >
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-info-square" /></span>
                                                    <p align="justify">
                                                        Informarea tinerilor NEETs din Regiunea Centru in vederea inregistrarii, consilierii si profilarii la nivelul SPO
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-info-square" /></span>
                                                    <p align="justify">
                                                        imbunatatirea nivelului de competente profesionale relevante pentru piata muncii pentru 186 de tineri NEETs B, C si D prin derularea de programe de formare profesionala;
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-info-square" /></span>
                                                    <p align="justify">
                                                        stimularea ocuparii sustenabile pe piata muncii a 371 de tineri NEETs A, B, C si D prin derularea unui program de mediere pe piata muncii folosind metode, tehnici si instrumente de cautare locuri de munca testate si validate in raport cu piata muncii din Romania;
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-info-square" /></span>
                                                    <p align="justify">
                                                        ocuparea efectiva a cel putin 160 de tineri NEETs;
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-info-square" /></span>
                                                    <p align="justify">
                                                        derularea unui program de formare antreprenoriala pentru 28 tineri NEETs nivel de ocupabilitate A, mentorat, consiliere in vederea dezvoltarii unor afaceri;
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-info-square" /></span>
                                                    <p align="justify">
                                                        Sprijinirea a 15 tineri NEETs A din grupul tinta in deschiderea unei afaceri prin acordarea a 15 subventii (micro-grant-uri) de 24.900 de euro/subventie in baza unei proceduri obiective si echidistante de evaluare si selectare prin concurs transparent si nediscriminatoriu, in urma unui curs de competente antreprenoriale organizat pentru 28 tineri NEETs A, oferirea de servicii personalizate de consiliere, consultanta si mentorat pentru pregatirea implementarii planurilor de afacere selectate, sprijin in infiintarea entitatilor sprijinite si monitorizarea functionarii conforme a entitatilor nou infiintate.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SelectareSiInscriereGrupTinta;
