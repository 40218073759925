import React from 'react';

const Cursuri = ({className}) => {
    return (
        <>
            <section className={`appie-services-2-area pt-100 pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Cursuri disponibile</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Ospatar: </b>
                                </p>
                                <ul>
                                    <li>
                                        <b>•</b> Curs de nivel 3
                                    </li>
                                    <li>
                                        <b>•</b> Durata: 720 ore
                                    </li>
                                    <li>
                                        <b>•</b> O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore)
                                    </li>
                                    <li>
                                        <b>•</b> Pot participa tinerii din categoriile B, C si D
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Bucatar: </b>
                                </p>
                                <ul>
                                    <li>
                                        <b>•</b> Curs de nivel 3
                                    </li>
                                    <li>
                                        <b>•</b> Durata: 720 ore
                                    </li>
                                    <li>
                                        <b>•</b> O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore)
                                    </li>
                                    <li>
                                        <b>•</b> Pot participa tinerii din categoriile B, C si D
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Operator introducere validare baze de date: </b>
                                </p>
                                <ul>
                                    <li>
                                        <b>•</b> Curs de nivel 3
                                    </li>
                                    <li>
                                        <b>•</b> Durata: 720 ore
                                    </li>
                                    <li>
                                        <b>•</b> O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore)
                                    </li>
                                    <li>
                                        <b>•</b> Pot participa tinerii din categoriile B, C si D
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Lucrator in comert:  </b>
                                </p>
                                <ul>
                                    <li>
                                        <b>•</b> Curs de nivel 2
                                    </li>
                                    <li>
                                        <b>•</b> Durata: 360 ore
                                    </li>
                                    <li>
                                        <b>•</b> O treime reprezinta teorie (120 ore) si doua treimi reprezinta practica (240 ore)
                                    </li>
                                    <li>
                                        <b>•</b> Pot participa tinerii din categoriile B, C si D
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    <b>Limba spaniola: </b>
                                </p>
                                <ul>
                                    <li>
                                        <b>•</b> Curs de specializare
                                    </li>
                                    <li>
                                        <b>•</b> Durata: minim 40 ore
                                    </li>
                                    <li>
                                        <b>•</b> O treime reprezinta teorie (13 ore) si doua treimi reprezinta practica (27 ore)
                                    </li>
                                    <li>
                                        <b>•</b> Pot participa tinerii din categoriile B, C si D
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                                style={{backgroundColor: '#3ab094'}}
                            >
                                <div className="icon">
                                    <i className="fas fa-users" style={{backgroundColor: '#c66060'}} />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px', color: 'white'}}>
                                    <b>Vrei sa te inscrii? </b>
                                </p>
                                <br/>
                                <p align="justify" style={{fontSize: '20px', color: 'white'}}>
                                    <b>Contacteaza-ne pentru detalii:</b>
                                </p>
                                <br/>
                                <p align="justify" style={{fontSize: '20px', color: 'white'}}>
                                    <b>neetnomorecentru@gmail.com</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Cursuri;
