import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axiosInstance from "../Helper/AxiosHelper";
import {atom, useAtom} from "jotai";
import BorderSpinner from "../../lib/BorderSpinner";

export const userAtom = atom(null);
const theme = createTheme();


const Login = () => {
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);
    const [showWrongCredentials, setShowWrongCredentials] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("email", event.target.email.value.toString().trim());
        formData.append("password", event.target.password.value.toString());
        try {
            setLoading(true);
            const response = await axiosInstance.post('/api/cntr/user/login', formData, {
                headers: {"Content-Type": "application/json"}
            })
            if (!(response.status === 200)) {
                console.log(response);
                setLoading(false);
                throw new Error(`Error! status: ${response.status}`);
            }
            const res = await response;
            setUserFromAtom(res.data.email);
            localStorage.setItem('user', res.data.email)
            localStorage.setItem('role', res.data.role)
            setShowWrongCredentials(false);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setShowWrongCredentials(true);

        }
    };

    const handleLogout = () => {
        setUserFromAtom(null);
        localStorage.clear();
    };

    return (
        <ThemeProvider theme={theme}>
            {localStorage.getItem('user') && (
                <div className="row" style={{paddingTop: '100px'}}>
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3>{localStorage.getItem('user')}  <b>este conectat!</b></h3>
                        </div>
                    </div>
                </div>
            )}
            {isLoading && (
                <div className="row" style={{paddingTop: '100px'}}>
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <p>Se conecteaza la platforma</p>
                            <BorderSpinner />
                        </div>
                    </div>
                </div>

            )}
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 8,
                    }}
                >
                    {localStorage.getItem('user') && (
                        <>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOpenOutlinedIcon />
                            </Avatar>
                            <Box component="form" onSubmit={handleLogout} noValidate sx={{ mt: 1 }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Deconectare
                                </Button>
                            </Box>
                        </>

                    )}
                    {!localStorage.getItem('user') && !isLoading && (
                        <>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Adresa de email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Parola"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Retine datele"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Conectare
                                </Button>
                                {showWrongCredentials && <span>Utilizator sau parola gresite</span>}
                            </Box>
                        </>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
