import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import Navigation from "../Navigation";
import {Link, Navigate} from "react-router-dom";
import Home from "./Home";
import {userAtom} from "../Login/Login";
import {useAtom} from "jotai";

const Header = ({ action }) => {
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);
    useEffect(() => {
        StickyMenu();
        if (localStorage.getItem("user")) {
            setUserFromAtom(localStorage.getItem("user"))
        }
    }, [setUserFromAtom]);

    const handleLogout = () => {
        setUserFromAtom(null);
        localStorage.clear();
        if (window.location.pathname === '/incarca-plan-de-afaceri' ||
            window.location.pathname === '/documente-admin' ||
            window.location.pathname === '/job' ||
            window.location.pathname === '/activitate') {
                console.log(window.location.pathname)
                window.location.pathname = '/'
        }
    };

    return (
        <>
            <header className="appie-header-area appie-header-4-area appie-sticky">
                <div className="container">
                    <div className="header-nav-box header-nav-box-5">
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-2 col-sm-2 order-3 order-sm-2">
                                <div className="appie-header-main-menu">
                                    <Navigation />
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-8 col-sm-8 col-8 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">
                                    {localStorage.getItem("user") && (
                                        // <a className="login-btn" onClick={handleLogout}>
                                        //     <i className="fal fa-user"></i> Logout
                                        // </a>
                                        <Link className="login-btn" onClick={handleLogout} to="#">
                                            <i className="fal fa-user"></i>Logout
                                        </Link>
                                    )}
                                    {!localStorage.getItem("user") && (
                                        // <a className="login-btn" >
                                        //     <i className="fal fa-user"></i> Login
                                        // </a>
                                        <Link className="login-btn" to="/login">
                                            <i className="fal fa-user"></i>Login
                                        </Link>
                                    )}
                                    {window.location.pathname === "/" && (
                                        <a className="main-btn ml-30" href="https://app.neetnomorecentru.ro/" target="_blank">
                                            Acces platforma admin
                                        </a>
                                    )}

                                    <div
                                        onClick={(e) => action(e)}
                                        className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                    >
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
